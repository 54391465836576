@use '../global' as *;

// font-family
.ff-sans-serif {
  font-family: $font-family-sans-serif;
}
.ff-serif {
  font-family: $font-family-serif;
}
.ff-daruma {
  font-family: $ff-daruma;
}

// color
@each $color, $value in $theme-colors {
  .text-#{$color} {
    color: $value;
  }
}

// size
@each $breakpoint in map-keys($grid-breakpoints) {
  @include mp-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @for $i from 10 through 20 {
      .fs#{$infix}-#{$i} {
        font-size: #{$i}px;
      }
    }
  }
}

// weight
.fw-bold {
  font-weight: bold;
}
.fw-normal {
  font-weight: normal;
}
