@use '../global' as *;

.h-100vh {
  height: 100vh;
}

.h-100dvh {
  height: 100vh;
  @supports (height: 100dvh) {
    height: 100dvh;
  }
}
