@use '../global' as *;

.global-header {
  padding: clamp(8px, 1.25vw, 16px);
  background: $dark-blue;
}
.global-header-nav {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;
  width: min(100%, 1100px);
  margin-inline: auto;
  &__btn {
    flex: 1 0 0;
    display: grid;
    place-items: center;
    padding: 8px;
    background: #fff;
    border-radius: 50em;
    color: $dark-blue;
    font-size: clamp(12px, 1.25vw, 16px);
    line-height: 1.25;
  }
}
