@use '../global' as *;

@each $breakpoint in map-keys($grid-breakpoints) {
  @include mp-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    .justify-content#{$infix}-start {
      justify-content: start;
    }
    .justify-content#{$infix}-end {
      justify-content: end;
    }
    .justify-content#{$infix}-center {
      justify-content: center;
    }
    .justify-content#{$infix}-between {
      justify-content: space-between;
    }

    .align-items#{$infix}-start {
      align-items: start;
    }
    .align-items#{$infix}-end {
      align-items: end;
    }
    .align-items#{$infix}-center {
      align-items: center;
    }

    .flex#{$infix}-wrap {
      flex-wrap: wrap;
    }
    .flex#{$infix}-nowrap {
      flex-wrap: nowrap;
    }
  }
}
