@font-face {
  font-family: 'noto_sans';
  src: url('../fonts/NotoSansJP-Regular.otf') format('opentype');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'noto_sans';
  src: url('../fonts/NotoSansJP-Bold.otf') format('opentype');
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'Darumadrop One';
  src: url('../fonts/DarumadropOne-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
}

/* stylelint-disable */
$font-family-sans-serif: 'Noto Sans', 'noto_sans', system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue',
  'Liberation Sans', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$font-family-serif: 'Times New Roman', 'YuMincho', 'Hiragino Mincho ProN', 'Yu Mincho', 'Noto Serif', 'MS PMincho',
  serif;
$ff-daruma: 'Darumadrop One', sans-serif;
/* stylelint-enable */
