@use '../global' as *;
@use 'sass:math';

.concierge-form {
  &__btn {
    width: fit-content;
    margin-inline: auto;
  }
}
.concierge-form-step {
  padding-block: clamp(48px, 5vw, 64px);
  background: none;
  border: none;
  &:first-of-type {
    padding-top: 0;
  }
  &:nth-of-type(2n) {
    border-image-source: linear-gradient(rgba($dark-green, 0.1), rgba($dark-green, 0.1));
    border-image-slice: 0 fill;
    border-image-outset: 0 100vw;
  }
  &__title {
    margin: 0 auto clamp(24px, 2.5vw, 32px);
    text-align: center;
    .eng {
      margin-right: clamp(12px, 1.56vw, 20px);
      color: $dark-green;
      font-family: $ff-daruma;
      font-size: clamp(24px, 2.5vw, 32px);
      em {
        font-size: clamp(36px, 4.375vw, 56px);
        font-style: normal;
      }
    }
    .jpn {
      font-size: clamp(16px, 2.19vw, 28px);
      font-weight: bold;
    }
  }
}

.parameter-card-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px 12px;
  @include mp-breakpoint-up(md) {
    gap: 16px 15px;
  }
  @include mp-breakpoint-up(lg) {
    gap: 32px 20px;
  }
  &__item {
    width: calc(50% - 6px);
    @include mp-breakpoint-up(md) {
      width: calc(33.3333% - 10px);
    }
    @include mp-breakpoint-up(lg) {
      width: calc(25% - 15px);
    }
  }
}
.parameter-card {
  position: relative;
  border: 2px transparent solid;
  border-radius: 16px;
  cursor: pointer;
  overflow: hidden;
  &__icon {
    position: absolute;
    top: 10px;
    left: 10px;
    color: #8ddb54;
    display: none;
  }
  &__title {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 8px;
    background: linear-gradient(rgba(#1d1d1d, 0.1) 0%, #1d1d1d 100%);
    color: #fff;
    font-size: clamp(16px, 1.41vw, 18px);
    font-weight: bold;
    text-align: center;
  }
  &.is-selected {
    border-color: $dark-green;
    .parameter-card__icon {
      display: block;
    }
  }
}

.range-list {
  display: flex;
  flex-wrap: wrap;
  gap: clamp(16px, 1.875vw, 24px) 8px;
  width: 100%;
  margin-top: clamp(32px, 5vw, 64px);
  &__item {
    width: 100%;
    @include mp-breakpoint-up(md) {
      width: calc(50% - 4px);
    }
  }
}
.input-range {
  &__label {
    display: block;
    margin-bottom: 16px;
    font-weight: bold;
    text-align: center;
  }
  &__contents {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }
}
.concierge-which-slider {
  flex: 1 0 0;
  height: 16px;
  appearance: none;
  background: #fff;
  border: 2px #d3d3d3 solid;
  border-radius: 8px;
  box-sizing: border-box;
  outline: none;
  cursor: pointer;
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 24px;
    height: 24px;
    background: $dark-green;
    border: 2px #f0f0f0 solid;
    border-radius: 12px;
    box-sizing: border-box;
  }
  &::-moz-range-thumb {
    width: 24px;
    height: 24px;
    background: $dark-green;
    border: 2px #f0f0f0 solid;
    border-radius: 12px;
    box-sizing: border-box;
  }
}

.schedule-select-list {
  display: flex;
  flex-wrap: wrap;
  gap: clamp(12px, 1.25vw, 16px) 12px;
}
.schedule-select-list-item {
  width: 100%;
  @include mp-breakpoint-up(md) {
    width: calc(50% - 6px);
  }
  &__label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
  }
  .select-wrap {
    position: relative;
    border: 1px #bdbdbd solid;
    border-radius: 4px;
    &::after {
      display: none;
    }
    &__icon {
      position: absolute;
      top: 2px;
      right: 8px;
    }
    select {
      height: 40px;
      background: none;
      font-size: 16px;
    }
  }
}

.concierge-loader {
  position: relative;
  margin-top: 24px;
  padding-top: 100px;
  &__spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: center;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border-width: 8px;
    border-style: solid;
    border-color: $dark-green rgba($light-green, 0.5) rgba($light-green, 0.5);
    -webkit-animation: spinner 1.5s infinite linear forwards;
    animation: spinner 1.5s infinite linear forwards;
    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 12px;
      line-height: 1;
      animation: loading 1.5s infinite linear forwards reverse;
    }
  }
}
@keyframes spinner {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@keyframes loading {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg);
  }
  50% {
    opacity: 0;
    transform: translate(-50%, -50%) rotate(180deg);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.concierge-result {
  padding-top: clamp(32px, 5vw, 64px);
  &__error {
    color: $red;
  }
}
