@use '../global' as *;
@use 'sass:math';

@each $breakpoint in map-keys($grid-breakpoints) {
  @include mp-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @for $i from 0 through 20 {
      .column-gap#{$infix}-#{$i} {
        column-gap: math.div($i, 2) * 16px;
      }
      .row-gap#{$infix}-#{$i} {
        row-gap: math.div($i, 2) * 16px;
      }
      .gap#{$infix}-#{$i} {
        gap: math.div($i, 2) * 16px;
      }
    }
  }
}
