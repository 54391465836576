@use '../global' as *;
@use 'sass:math';

@each $breakpoint in map-keys($grid-breakpoints) {
  @include mp-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @for $i from 0 through 20 {
      .border-radius#{$infix}-#{$i} {
        border-radius: math.div($i, 4) * 16px !important;
      }

      .border-radius-s#{$infix}-#{$i} {
        border-radius: (math.div($i, 4) * 16px) 0 0 (math.div($i, 4) * 16px) !important;
      }
      .border-radius-t#{$infix}-#{$i} {
        border-radius: (math.div($i, 4) * 16px) (math.div($i, 4) * 16px) 0 0 !important;
      }
      .border-radius-e#{$infix}-#{$i} {
        border-radius: 0 (math.div($i, 4) * 16px) (math.div($i, 4) * 16px) 0 !important;
      }
      .border-radius-b#{$infix}-#{$i} {
        border-radius: 0 0 (math.div($i, 4) * 16px) (math.div($i, 4) * 16px) !important;
      }

      .border-radius-st#{$infix}-#{$i} {
        border-radius: (math.div($i, 4) * 16px) 0 0 0 !important;
      }
      .border-radius-te#{$infix}-#{$i} {
        border-radius: 0 (math.div($i, 4) * 16px) 0 0 !important;
      }
      .border-radius-eb#{$infix}-#{$i} {
        border-radius: 0 o (math.div($i, 4) * 16px) 0 !important;
      }
      .border-radius-bs#{$infix}-#{$i} {
        border-radius: 0 0 0 (math.div($i, 4) * 16px) !important;
      }
    }
  }
}
