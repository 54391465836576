$white: #fff;
$black: #000;

$gray: #6c757d;
$gray-100: #f8f9fa;
$gray-200: #c3c3c3;
$gray-300: #bdbdbd;

$blue: #4f9dbf;
$dark-blue: #278eb0;
$light-blue: #a5c8d4;
$green: #97bf7c;
$dark-green: #6fac45;
$light-green: #d1e09b;
$orange: #f19952;
$dark-orange: #eb8430;
$light-orange: #ffbd88;
$red: #db1b1b;
$pink: #ff729e;

$primary: $blue;
$secondary: $gray;
$light: $gray-100;

$color-text: $black;
$color-link: $black;

$color-twitter: #000;
$color-facebook: #3365a8;
$color-line: #1ab833;

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'blue': $blue,
  'dark-blue': $dark-blue,
  'green': $green,
  'dark-green': $dark-green,
  'orange': $orange,
  'dark-orange': $dark-orange,
  'light': $light,
  'gray': $gray,
  'gray-200': $gray-200,
  'red': $red,
  'pink': $pink,
  'white': $white,
  'black': $black,
);

$area-colors: (
  'kurecenter': (
    1,
    #fe7701,
  ),
  'aga': (
    2,
    #8677c7,
  ),
  'kawajiri': (
    3,
    #ffa500,
  ),
  'tobishima': (
    4,
    #009dd4,
  ),
  'ondo': (
    5,
    #4fa738,
  ),
  'miyahara': (
    6,
    #e289af,
  ),
  'yakeyama': (
    7,
    #308ebe,
  ),
);
