@use '../global' as *;

.aspect-ratio {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &--1x1 {
    img {
      aspect-ratio: 1/1;
    }
  }
  &--3x2 {
    img {
      aspect-ratio: 3/2;
    }
  }
  &--4x3 {
    img {
      aspect-ratio: 4/3;
    }
  }
  &--16x9 {
    img {
      aspect-ratio: 16/9;
    }
  }
}
