@use '../global' as *;
@use 'sass:math';

.course-contents-header {
  margin-bottom: clamp(40px, 6.25vw, 80px);
  padding: clamp(48px, 6.25vw, 80px) clamp(24px, 3.75vw, 48px) clamp(24px, 4.375vw, 56px);
  background: rgba($dark-blue, 0.1);
  &__title {
    position: relative;
    width: fit-content;
    margin-inline: auto;
    padding-inline: clamp(32px, 3.125vw, 40px);
    font-size: clamp(24px, 2.19vw, 28px);
    &::before {
      position: absolute;
      bottom: 100%;
      left: 50%;
      width: 90vw;
      color: $dark-blue;
      content: attr(data-ruby);
      font-size: clamp(12px, 1.25vw, 16px);
      text-align: center;
      transform: translateX(-50%);
    }
    .txt {
      display: grid;
      place-items: center;
    }
    .icon {
      position: absolute;
      top: 50%;
      left: 0;
      color: $dark-blue;
      font-size: clamp(20px, 1.875vw, 24px);
      line-height: 1;
      transform: translateY(-50%);
    }
  }
}

.course-detail-tab-panel {
  display: none;
  padding-top: clamp(40px, 6.25vw, 80px);
  &.is-active {
    display: block;
  }
}

.course-day-spot {
  &:not(:last-of-type) {
    background-image: linear-gradient(
      #000 0,
      #000 25%,
      transparent 25%,
      transparent 50%,
      #000 50%,
      #000 75%,
      transparent 75%,
      transparent 100%
    );
    background-repeat: repeat-y;
    background-position: 27px 0;
    background-size: 1px 8px;
    @include mp-breakpoint-up(md) {
      background-position: 42px 0;
    }
  }
  &:last-of-type {
    margin-bottom: clamp(40px, 6.25vw, 80px);
  }
  &__lead {
    margin-bottom: clamp(16px, 1.875vw, 24px);
    padding-left: 62px;
    line-height: 2;
    @include mp-breakpoint-up(md) {
      padding-right: 40px;
      padding-left: 152px;
    }
  }
}
.course-day-spot-header {
  display: flex;
  align-items: start;
  &__position {
    flex-shrink: 0;
    display: grid;
    place-items: center;
    width: 54px;
    height: 54px;
    margin-right: 8px;
    background: $dark-blue;
    border-radius: 50%;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    @include mp-breakpoint-up(md) {
      width: 84px;
      height: 84px;
      margin-right: 28px;
      font-size: 20px;
    }
  }
  &__name {
    color: $dark-blue;
    font-size: clamp(14px, 1.56vw, 20px);
    font-weight: bold;
  }
  &__time {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 16px;
    padding-top: 8px;
    border-top: 1px $dark-blue dashed;
    font-size: clamp(12px, 1.41vw, 18px);
    .icon {
      flex-shrink: 0;
      margin-right: clamp(8px, 1.25vw, 16px);
      color: $dark-blue;
      font-size: clamp(18px, 1.56vw, 20px);
      line-height: 1;
    }
  }
}
.course-day-spot-header-info {
  flex: 1 0 0;
  margin-bottom: 32px;
  padding: clamp(12px, 1.875vw, 24px) clamp(16px, 3.125vw, 40px) clamp(12px, 1.25vw, 16px);
  background: rgba($dark-blue, 0.1);
  border-radius: 8px;
}

.course-day-spot-contents {
  margin-bottom: 40px;
  padding-left: 62px;
  @include mp-breakpoint-up(md) {
    display: grid;
    align-items: center;
    grid-template-columns: 58% 37%;
    gap: 32px 5%;
    padding-right: 40px;
    padding-left: 152px;
  }
  &__img {
    margin-bottom: 8px;
    border-radius: 8px;
    overflow: hidden;
    @include mp-breakpoint-up(md) {
      grid-area: 1 / 1 / span 2 / 1;
      margin-bottom: 0;
    }
    img {
      width: 100%;
      height: 100%;
      aspect-ratio: 16/9;
      object-fit: cover;
    }
  }
  &__descripion {
    font-size: clamp(14px, 1.25vw, 16px);
    @include mp-breakpoint-up(md) {
      grid-area: 1 / 2 / 1 / 2;
    }
  }
  &__btn {
    margin-top: 16px;
    @include mp-breakpoint-up(md) {
      margin-top: 0;
      grid-area: 2 / 2 / 2 / 2;
    }
  }
}
.course-day-spot-transit {
  position: relative;
  padding-left: 62px;
  padding-bottom: 40px;
  @include mp-breakpoint-up(md) {
    padding-left: 112px;
  }
  &__icon {
    color: $dark-green;
    font-size: clamp(30px, 2.81vw, 36px);
    line-height: 1;
  }
  &__txt {
    font-size: clamp(13px, 1.41vw, 18px);
    font-weight: bold;
  }
  &::before {
    position: absolute;
    top: clamp(19px, 1.72vw, 22px);
    left: 19px;
    width: 16px;
    height: 16px;
    background: #c3c3c3;
    border-radius: 50%;
    content: '';
    @include mp-breakpoint-up(md) {
      left: 34px;
    }
  }
}
.course-day-spot-transit-contents {
  display: flex;
  align-items: center;
  column-gap: clamp(16px, 1.72vw, 22px);
  width: fit-content;
  padding: 12px 32px;
  background: rgba($dark-green, 0.1);
  border-radius: 50px;
}

.course-footer {
  position: relative;
  margin-bottom: clamp(40px, 7.03vw, 90px);
  padding-block: clamp(20px, 3.125vw, 40px);
  border-radius: 8px;
  overflow: hidden;
  &__title {
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
    font-size: clamp(14px, 1.25vw, 16px);
    font-weight: bold;
    z-index: +1;
    &::before {
      content: '＼';
    }
    &::after {
      content: '／';
    }
  }
  &__btn {
    position: relative;
    width: fit-content;
    margin-inline: auto;
    z-index: +1;
  }
  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba($dark-blue, 0.6);
      content: '';
      mix-blend-mode: multiply;
    }
  }
}

.course-day-spot-recommendations {
  margin-bottom: 40px;
  padding-left: 62px;
  @include mp-breakpoint-up(md) {
    padding-left: 112px;
  }
  &__title {
    margin-bottom: clamp(12px, 1.25vw, 16px);
    color: $dark-green;
    font-size: clamp(16px, 1.875vw, 20px);
    @include mp-breakpoint-up(md) {
      display: flex;
      padding-inline: 40px;
    }
    &::before {
      display: block;
      content: attr(data-en);
      font-size: 12px;
      text-transform: uppercase;
      @include mp-breakpoint-up(md) {
        content: attr(data-en) '｜';
        font-size: clamp(16px, 1.875vw, 20px);
      }
    }
  }
}
.course-day-spot-recommendations-block {
  @include mp-breakpoint-up(md) {
    padding-inline: 40px;
  }
}
.course-day-spot-recommendations-contents {
  position: relative;
  padding: clamp(16px, 3.125vw, 40px);
  background: #f2f2f2;
  border-radius: 8px;
  @include mp-breakpoint-up(md) {
    display: grid;
    align-items: center;
    grid-template-columns: 58% 37%;
    column-gap: 5%;
  }
  &__balloon {
    position: relative;
    width: fit-content;
    max-width: 100%;
    margin-bottom: 16px;
    padding: clamp(4px, 0.625vw, 8px);
    background: #fff;
    border: 2px $green solid;
    border-radius: 8px;
    font-size: 12px;
    word-break: break-all;
    @include mp-breakpoint-up(md) {
      grid-area: 1 / 1 / 1 / span 2;
    }
    &::before {
      position: absolute;
      bottom: -12px;
      left: 8px;
      display: block;
      width: 16px;
      height: 12px;
      background: $green;
      clip-path: polygon(0 0, 50% 100%, 100% 0);
      content: '';
    }
    &::after {
      position: absolute;
      bottom: -11px;
      left: 8px;
      display: block;
      width: 16px;
      height: 12px;
      background: #fff;
      clip-path: polygon(2px 0, 50% calc(100% - 2px), calc(100% - 2px) 0);
      content: '';
    }
  }
  &__heading {
    margin-bottom: 16px;
    font-size: clamp(16px, 1.875vw, 20px);
    font-weight: bold;
    word-break: break-all;
    @include mp-breakpoint-up(md) {
      grid-area: 2 / 1 / 2 / span 2;
    }
  }
  &__img {
    margin-bottom: 8px;
    border-radius: 8px;
    overflow: hidden;
    @include mp-breakpoint-up(md) {
      grid-area: 3 / 1 / span 3 / 1;
      margin-bottom: 0;
    }
    img {
      width: 100%;
      height: 100%;
      aspect-ratio: 16/9;
      object-fit: cover;
    }
  }
  &__name {
    margin-bottom: 8px;
    font-size: clamp(16px, 1.875vw, 20px);
    font-weight: bold;
    @include mp-breakpoint-up(md) {
      grid-area: 3 / 2 / 3 / 2;
    }
  }
  &__description {
    @include mp-breakpoint-up(md) {
      grid-area: 4 / 2 / 4 / 2;
    }
  }
  &__btn {
    margin-top: 16px;
    @include mp-breakpoint-up(md) {
      margin-top: 0;
      grid-area: 5 / 2 / 5 / 2;
    }
  }
}
.course-day-spot-recommendations-swiper {
  position: relative;
  @include mp-breakpoint-up(md) {
    padding-inline: 40px;
  }
}
.course-day-spot-recommendations-swiper-nav {
  position: absolute;
  top: 50%;
  display: grid;
  place-items: center;
  width: 24px;
  height: 24px;
  background: $dark-green;
  border-radius: 50%;
  color: #fff;
  z-index: +1;
  @include mp-breakpoint-up(md) {
    width: 36px;
    height: 36px;
  }
  &--prev {
    left: 0;
    transform: translateX(-50%);
    @include mp-breakpoint-up(md) {
      left: 40px;
    }
  }
  &--next {
    right: 0;
    transform: translateX(50%);
    @include mp-breakpoint-up(md) {
      right: 40px;
    }
  }
}
