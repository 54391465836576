@use '../global' as *;

.border {
  border-width: 1px;
  border-style: solid;
}
.border-top {
  border-width: 1px 0 0;
  border-style: solid;
}
.border-end {
  border-width: 0 1px 0 0;
  border-style: solid;
}
.border-bottom {
  border-width: 0 0 1px;
  border-style: solid;
}
.border-start {
  border-width: 0 0 0 1px;
  border-style: solid;
}

@each $color, $value in $theme-colors {
  .border-#{$color} {
    border-color: $value;
  }
}
