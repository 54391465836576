@use '../global' as *;
@use 'sass:math';

.position-relative {
  position: relative;
}
.position-absolute {
  position: absolute;
}

.icon-pos-r {
  position: absolute;
  top: 50%;
  right: clamp(16px, 2.5vw, 32px);
  transform: translateY(-50%);
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include mp-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @for $i from 0 through 20 {
      .t#{$infix}-#{$i} {
        top: math.div($i, 2) * 16px;
      }
      .t#{$infix}-auto {
        top: auto;
      }
      .r#{$infix}-#{$i} {
        right: math.div($i, 2) * 16px;
      }
      .r#{$infix}-auto {
        right: auto;
      }
      .b#{$infix}-#{$i} {
        bottom: math.div($i, 2) * 16px;
      }
      .b#{$infix}-auto {
        bottom: auto;
      }
      .s#{$infix}-#{$i} {
        left: math.div($i, 2) * 16px;
      }
      .l#{$infix}-auto {
        left: auto;
      }
    }
  }
}
