@use '../global' as *;

%btn-basic {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
  width: 100%;
  padding: 4px 24px;
  border-radius: 50px;
  font-weight: bold;
  line-height: 1.25;
  text-decoration: none;
}

.btn-cmn {
  @extend %btn-basic;
  min-height: 68px;
  background: #f2f2f2;
  color: #000;
}
.btn-search {
  @extend %btn-basic;
  min-height: 68px;
  background: $dark-green;
  color: #fff;
  font-size: 20px;
}
.btn-reset {
  @extend %btn-basic;
  min-height: 68px;
  background: rgba(#000, 0.2);
  border: none;
  color: rgba(#000, 0.4);
  font-size: 20px;
  &--form {
    min-height: 48px;
    font-size: 18px;
  }
}

.btn-gray {
  @extend %btn-basic;
  min-height: 48px;
  background: #f2f2f2;
  border: 1px #f2f2f2 solid;
  font-size: 18px;
  /* stylelint-disable-next-line */
  transition: background 0.3s, border-color 0.3s;
  &:hover {
    @media (hover: hover) {
      background: #c3c3c3;
      border-color: #c3c3c3;
    }
  }
}
.btn-gray-outline {
  @extend %btn-basic;
  min-height: 48px;
  background: #fff;
  border: 1px #c3c3c3 solid;
  font-size: 18px;
  /* stylelint-disable-next-line */
  transition: background 0.3s, border-color 0.3s, color 0.3s;
  &:hover {
    @media (hover: hover) {
      background: #000;
      border-color: #000;
      color: #fff;
    }
  }
}

.btn-orange {
  @extend %btn-basic;
  min-height: 48px;
  background: $dark-orange;
  border: 1px $dark-orange solid;
  color: #fff;
  /* stylelint-disable-next-line */
  transition: background 0.3s, color 0.3s;
  &:hover {
    @media (hover: hover) {
      background: #fff;
      color: $dark-orange;
    }
  }
}
.btn-orange-outline {
  @extend %btn-basic;
  min-height: 48px;
  background: #fff;
  border: 1px $dark-orange solid;
  color: $dark-orange;
  font-size: 18px;
  /* stylelint-disable-next-line */
  transition: background 0.3s, color 0.3s;
  &:hover {
    @media (hover: hover) {
      background: $dark-orange;
      color: #fff;
    }
  }
}

.btn-green {
  @extend %btn-basic;
  min-height: 48px;
  background: $dark-green;
  border: 1px $dark-green solid;
  color: #fff;
  font-size: 18px;
  /* stylelint-disable-next-line */
  transition: background 0.3s, color 0.3s;
  &:hover {
    @media (hover: hover) {
      background: #fff;
      color: $dark-green;
    }
  }
}
.btn-green-outline {
  @extend %btn-basic;
  min-height: 48px;
  background: #fff;
  border: 1px $dark-green solid;
  color: $dark-green;
  font-size: 18px;
  /* stylelint-disable-next-line */
  transition: background 0.3s, color 0.3s;
  &:hover {
    @media (hover: hover) {
      background: $dark-green;
      color: #fff;
    }
  }
}

.btn-blue {
  @extend %btn-basic;
  min-height: 48px;
  background: $dark-blue;
  border: 1px $dark-blue solid;
  color: #fff;
  font-size: 18px;
  /* stylelint-disable-next-line */
  transition: background 0.3s, color 0.3s;
  &:hover {
    @media (hover: hover) {
      background: #fff;
      color: $dark-blue;
    }
  }
}
.btn-blue-outline {
  @extend %btn-basic;
  min-height: 48px;
  background: #fff;
  border: 1px $dark-blue solid;
  color: $dark-blue;
  font-size: 18px;
  /* stylelint-disable-next-line */
  transition: background 0.3s, color 0.3s, filter 0.3s;
  &:not([disabled]):hover {
    @media (hover: hover) {
      background: $dark-blue;
      color: #fff;
    }
  }
  &[disabled] {
    filter: grayscale(100%);
    cursor: not-allowed;
  }
}

[class^='btn'] {
  &.small-type {
    min-height: 40px;
    font-size: 16px;
  }
  &.large-type {
    min-height: 68px;
  }
  &.is-disabled {
    pointer-events: none;
    filter: grayscale(100%);
    cursor: not-allowed;
  }
}

.btn-favorite-spot {
  /* stylelint-disable-next-line */
  @extend .btn-gray-outline;
  &.is-active {
    background: #c3c3c3;
  }
}
