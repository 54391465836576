@use '../global' as *;

html {
  scroll-behavior: smooth;
}

body {
  position: relative;
  line-height: 1.5;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  font-style: italic;
  background-repeat: no-repeat;
  background-size: cover;
  shape-margin: 12px;
}
