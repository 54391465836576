@use '../global' as *;
@use 'sass:math';

.breadcrumbs {
  display: flex;
  width: min(100%, 1100px);
  margin-inline: auto;
  padding: clamp(12px, 1.25vw, 16px) math.div($grid-gutter-width, 2);
  border-image-source: linear-gradient(#fff, #fff);
  border-image-slice: 0 fill;
  border-image-outset: 0 100vw;
  color: #c3c3c3;
  column-gap: clamp(12px, 1.25vw, 16px);
  overflow-x: auto;
  & > * {
    display: block;
    width: fit-content;
    color: #000;
    text-decoration: none;
    white-space: nowrap;
  }
}

.bottom-breadcrumbs {
  width: fit-content;
  max-width: 100%;
  margin: 0 auto clamp(40px, 6.25vw, 80px);
  padding: clamp(12px, 1.25vw, 16px) math.div($grid-gutter-width, 2);
  overflow-x: auto;
}
.bottom-breadcrumbs-list {
  display: flex;
  max-width: 100%;
  row-gap: 8px;
  &__item {
    display: block;
    width: fit-content;
    font-size: clamp(14px, 1.25vw, 16px);
    line-height: 1;
    white-space: nowrap;
    &:not(:last-of-type) {
      position: relative;
      margin-right: 16px;
      padding-right: 24px;
      &::after {
        position: absolute;
        top: 50%;
        right: 0;
        display: block;
        width: 8px;
        height: 8px;
        border-right: 1px #c3c3c3 solid;
        border-bottom: 1px #c3c3c3 solid;
        content: '';
        transform: translateY(-50%) rotate(-45deg);
      }
    }
    a {
      text-decoration: none;
    }
  }
}
