@use '../global' as *;
@use 'sass:math';

$gutter: (math.div($grid-gutter-width, 4) * 3);

.main-contents {
  padding-bottom: clamp(40px, 6.25vw, 80px);
}
.main-contents-header {
  padding-block: clamp(48px, 6.25vw, 80px);
  background: rgba($dark-blue, 0.1);
  &__title {
    position: relative;
    width: fit-content;
    margin-inline: auto;
    padding-inline: clamp(32px, 3.125vw, 40px);
    font-size: clamp(24px, 2.34vw, 30px);
    font-weight: bold;
    .txt {
      order: 3;
      display: grid;
      place-items: center;
    }
    .icon {
      position: absolute;
      top: 50%;
      left: 0;
      color: $dark-blue;
      font-size: clamp(20px, 1.875vw, 24px);
      transform: translateY(-50%);
      line-height: 1;
      img,
      svg {
        width: 24px;
      }
    }
    .copy {
      order: 1;
      display: grid;
      place-items: center;
      width: 100%;
      color: $dark-blue;
      font-size: clamp(12px, 1.25vw, 16px);
    }
  }
}
.main-contents-body {
  padding-top: clamp(48px, 5.94vw, 76px);
}

.mp-container {
  width: min(100%, 1100px);
  margin-inline: auto;
  padding-inline: $gutter;
}
.mp-row {
  display: flex;
  flex-wrap: wrap;
  margin-inline: math.div(-$grid-gutter-width, 2);
}
@each $breakpoint in map-keys($grid-breakpoints) {
  @include mp-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @for $i from 1 through $grid-columns {
      .mp-col#{$infix}-#{$i} {
        flex: 0 0 auto;
        width: math.div($i, $grid-columns) * 100%;
        padding-inline: $gutter;
      }
    }
  }
}

.draft-emergency {
  width: fit-content;
  margin-inline: auto;
  padding: clamp(8px, 1.25vw, 16px);
  border: 1px $red solid;
  color: $red;
  text-align: center;
}

.jc-priority-transit-selector__btn {
  text-align: center;
}
